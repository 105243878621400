import React, { memo, useCallback } from 'react';
import pluralize from 'pluralize';
import clsx from 'clsx';
import { Screen } from '../Mobile';
import SearchActionArea from '../SearchActionArea';
import StepEnum from '../enum';
import GuestPicker from './GuestPicker';
import GuestModel from './GuestModel';
import LocationOutlinedIcon from '@/components/common/icons/LocationOutlined';
import DatesIcon from '@/components/common/icons/Dates';
import PersonIcon from '@/components/common/icons/Person';
import GuestsProgressive from '@/components/common/icons/GuestsProgressive';
import useProceedToSearch from '../useProceedToSearch';
import useInterests from '../Interests/useInterests';
import { DateRangeModel } from '../DateRange';
import { useDates } from '@/containers/searchResults/hooks';

const GuestPickerMobile = ({ destinationState, datesState, guests, step, onClose }) => {
    const [guestsState, setGuestsState] = guests;
    const [stepState, setStepState] = step;
    const getDates = useDates();


    const { data } = useInterests();
    const isActivitiyInterestsExists = data.length > 0;
    const validatedDatesState = new DateRangeModel(getDates({
        checkin: datesState.startDate,
        checkout: datesState.endDate,
        isVR: false
    }));
    const { startDate, endDate } = validatedDatesState.getValidDates('DD MMM');

    const onProceedToSearch = useProceedToSearch();

    const onCancel = useCallback(() => {
        setGuestsState(new GuestModel({ isVisited: true }));
    }, []);

    const onProceed = () => {
        if (isActivitiyInterestsExists &&
            guestsState.validate()) {
            setStepState(StepEnum.INTERESTS);
            return;
        }
        onProceedToSearch(destinationState, datesState, guestsState);
    };

    const ProceedToNextSlot =  isActivitiyInterestsExists
            ? (
                <SearchActionArea
                    className="omnisearch_input-wrapper_mobile_screen_guest-picker_next-btn"
                    isMobile
                    onClick={onProceed}
                >
                    Next
                </SearchActionArea>
            )
            : (
                <SearchActionArea
                    isMobile
                    onClick={onProceed}
                />
            );

    return (
        <Screen
            className="omnisearch_input-wrapper_mobile_screen_guest-picker"
            headerContentSlot={
                <div className="omnisearch_input-wrapper_mobile_screen_guest-picker_head">
                    <div className="omnisearch_input-wrapper_mobile_screen_guest-picker_head_title">
                        Your trip
                    </div>
                </div>
            }
            proceedToNextSlot={ProceedToNextSlot}
            priority={5}
            isOpen={[StepEnum.GUESTS, StepEnum.INTERESTS].includes(stepState)}
            onGoBack={() => setStepState(StepEnum.DATES)}
            onCancel={onCancel}
            onClose={onClose}
        >
            <div className="omnisearch_input-wrapper_mobile_screen_guest-picker_content">
                <div className="omnisearch_input-wrapper_mobile_screen_guest-picker_content_info-board">
                    <div
                        className="omnisearch_input-wrapper_mobile_screen_guest-picker_content_info-board_row"
                        onClick={() => setStepState(StepEnum.LOCATION)}
                    >
                        <LocationOutlinedIcon className="omnisearch_input-wrapper_mobile_screen_guest-picker_content_info-board_row_location-icon" />
                        <span>{destinationState.searchTerm}</span>
                    </div>
                    <div
                        className="omnisearch_input-wrapper_mobile_screen_guest-picker_content_info-board_row"
                        onClick={() => setStepState(StepEnum.DATES)}
                    >
                        <DatesIcon />
                        <span>{`${startDate} - ${endDate}`}</span>
                    </div>
                    <div
                        className={clsx(
                            'omnisearch_input-wrapper_mobile_screen_guest-picker_content_info-board_row',
                            // 'omnisearch_input-wrapper_mobile_screen_guest-picker_content_info-board_row--active'
                        )}
                    >
                        <GuestsProgressive className="filters-guest-selector_mobile-icon" />
                        <span>{`${pluralize('guest', guestsState.totalCount, true)} • ${pluralize('room', guestsState.roomCount, true)}`}</span>
                    </div>
                </div>
                <GuestPicker
                    value={guestsState}
                    onChange={setGuestsState}
                />
            </div>
        </Screen>
    );
};

export default memo(GuestPickerMobile);
