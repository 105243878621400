import React, { memo, useEffect, useRef } from 'react';

//Import methods
import { closePortal, enterAnimation, lockBody } from '../../../../utils/currencyMethods';

//Import components
import CurrencyCard from '../../../CurrencyCard';
import CurrencyCategory from '../../../CurrencyCategory';


const CurrencyBox = ({ state, info }) => {

    //Var
    const topC = info.data.top_currencies;
    const cryptoCurrencies = info.data.crypto_currencies;
    const bottomC = info.data.normal_currencies;

    //Conditions
    const hasLoadedData = !!info.data.top_currencies && !!info.data.normal_currencies;

    //Refs
    const parentRef = useRef(null);
    const childRef = useRef(null);

    //Methods
    useEffect(() => {
        lockBody(true);
        enterAnimation(parentRef.current, childRef.current);
    }, []);

    //Render
    const renderTopCards = hasLoadedData && topC.map((el, i) => <CurrencyCard selected={info.currency} info={el} close={{ parent: parentRef, child: childRef, state }} from='desktop' key={i} />);
    const renderBottomCards = hasLoadedData && bottomC.map((el, i) => <CurrencyCard selected={info.currency} info={el} close={{ parent: parentRef, child: childRef, state }} from='desktop' key={i} />);


    return (
        <div className='cn__currency__desktop__portal' onClick={() => closePortal(parentRef.current, childRef.current, state)} ref={parentRef}>

            {/* ---------- WHITE BOX ---------- */}
            <div className='cn__currency__desktop__portal__box' onClick={(e) => e.stopPropagation()} ref={childRef}>
                {/* ---------- HEADER ---------- */}
                <div className='cn__currency__desktop__portal__box__header'>
                    <p className='cn__currency__desktop__portal__box__header__title'>Select currency</p>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => closePortal(parentRef.current, childRef.current, state)}>
                        <path d="M7.73047 8L15.9307 16.4853" stroke="#242526" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M15.9297 8L7.72944 16.4853" stroke="#242526" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                {/* ---------- INFO ---------- */}
                <div className='cn__currency__desktop__portal__box__info'>
                    {/* ---------- TOP CURRENCIES ---------- */}
                    <div className='cn__currency__desktop__portal__box__info__top'>
                        <p className='cn__currency__desktop__portal__box__info__top__title'>
                            Popular currencies
                        </p>
                        <div className='cn__currency__desktop__portal__box__info__top__currencies'>
                            {renderTopCards}
                        </div>
                    </div>
                    {/* ---------- CRYPTO CURRENCIES ---------- */}
                    <CurrencyCategory
                        CardProps={{
                            selected: info.currency,
                            close: {
                                parent: parentRef,
                                child: childRef,
                                state
                            },
                            from: 'desktop'
                        }}
                        currencies={(
                            hasLoadedData && cryptoCurrencies
                        )}
                    >
                        Cryptocurrencies
                    </CurrencyCategory>
                    {/* ---------- BOTTOM CURRENCIES ---------- */}
                    <div className='cn__currency__desktop__portal__box__info__bottom'>
                        <p className='cn__currency__desktop__portal__box__info__bottom__title'>
                            All currencies
                        </p>
                        <div className='cn__currency__desktop__portal__box__info__bottom__currencies'>
                            {renderBottomCards}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default memo(CurrencyBox);