
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import usePropertyPrice from './usePropertyPrice';
import Discount from './Discount';
import Skeleton from "react-loading-skeleton";

const Price = ({ parent, price, numberOfDays, isMobile, property }) => {
    const {
        isPriceValid,
        isDiscountApplied,
        getOriginalPrice,
        getDiscountEntity,
        getTotalPrice,
        renderTaxesAndFees,
        getNumberOfguestText
    } = usePropertyPrice({ price, numberOfNights: Math.round(numberOfDays) });

    if (!isPriceValid()) {
        return (
            <Skeleton />
        );
    }

    /*const discountPercentage = useMemo(() => {
        if (price.original_price) {
            return Number(price.discount) || (100 - Math.round((price.amount * 100) / price.original_price));
        }
        return 0;
    }, [price.original_price, price.amount, price.discount]);*/

    return (
        <>
            {/* {originalPrice && (
                <>
                    <PromoRibbon isMobile={isMobile} className="property-card_price-discount">{`${discountPercentage}% off`}</PromoRibbon>
                    <div className="property-card_price-original">
                        {originalPrice}
                    </div>
                </>
            )} */}
            <div className={'property-card_price-actual'}>
                {/* {isHotel && (
                    <p className='property-card_price-actual__rooms'>
                        Rooms from
                    </p>
                )} */}

                <div>
                    <span className="property-card_price-actual__active">
                        {getNumberOfguestText()}
                    </span>
                </div>
            </div>
            <div className={clsx('property-card_price-actual property-card_price-total', {
                'property-card_price-actual__discounted': Boolean(getOriginalPrice()),
                'property-card_price-actual--small': isMobile
            })}>
               <Discount
                    {...getDiscountEntity()}
                    parent={parent}
                    isActive={isDiscountApplied()}
                />
                <span className={clsx(Boolean(isDiscountApplied()) && 'property-card_price-actual__havediscount')}>{getTotalPrice()}</span>
            </div>
            <span className='property-card_price-info'>
                {renderTaxesAndFees()}
            </span>
        </>
    );
};

Price.propTypes = {
    price: PropTypes.object
};

Price.defaultProps = {
    price: {}
};

export default memo(Price);
