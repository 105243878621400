import React, { memo, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { useOmnisearchState } from './contexts/OmnisearchState';
import Settings from './contexts/Settings';
import useLocationsNearby from './contexts/LocationsNearby';
import { getIsMobile } from './contexts/Settings/Settings';
import useInitOmnisearch from './useInitOmnisearch';

const Omnisearch = ({
    className,
    withLocationsNearby,
    withAttractionsNearby,
    withRecentSearches,
    withActivityInterests,
    onMobileOmnisearchOpen,
    onInit
}) => {
    useLocationsNearby(withLocationsNearby);

    const {
        step,
        destination,
        dates,
        guests
    } = useInitOmnisearch(onInit, {
        withAttractionsNearby
    });

    if (typeof window === 'undefined') {
        return null;
    }

    const Component = getIsMobile() ? Mobile : Desktop;

    return (
        <Settings
            withLocationsNearby={withLocationsNearby}
            withAttractionsNearby={withAttractionsNearby}
            withRecentSearches={withRecentSearches}
            withActivityInterests={withActivityInterests}
        >
            <div className={clsx('omnisearch', className)}>
                <Component
                    destination={destination}
                    dates={dates}
                    guests={guests}
                    step={step}
                    onMobileOmnisearchOpen={onMobileOmnisearchOpen}
                />
            </div>
        </Settings>
    );
};

Omnisearch.defaultProps = {
    withLocationsNearby: true,
    withAttractionsNearby: true,
    withRecentSearches: true,
    withActivityInterests: true
};

export default memo(Omnisearch);
