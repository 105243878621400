import React, { memo } from 'react';

//Import libraries
import Link from 'next/link';
import SimpleBar from 'simplebar-react';

//Import methods
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';

//Import Data
import { options1, options2, options3, options4, options5 } from './data';

//Import methods
import { getYear } from './utils';

//Components
import List from './components/List';
import Social from './components/Social';
import analyticsLabelProvider from '@/static/analyticsLabelProvider';
import { EAType } from '@/static/contants';

const Footer = ({location,handleLogIn}) => {

    //Check size
    const { isMobile, isDesktop, isTablet } = useDeviceDetect();

    //Vars
    const year = getYear();

    return (
        <footer className="cn__footer__wrapper">
            <div className="cn__footer">
                <div className="cn__footer__top">
                    <div className="cn__footer__top__titlewrapper">
                        <p className="cn__footer__top__title">
                            Travel is for everyone<span>.</span>
                        </p>
                        {isDesktop && (
                            <div className="cn__footer__bottom__sec2">
                                <Social/>
                            </div>
                        )}
                    </div>
                    <div className="cn__footer__top__options">
                        {isMobile ? (
                            <>
                                <SimpleBar>
                                    <div className="slidermobile__wrapper">
                                        <div className="cn__footer__top__options__option">
                                            <p className="cn__footer__top__options__option__category">Cuddlynest</p>
                                            {options1.map((el,i) => <List data={el} key={i} handleLogIn={handleLogIn}/>)}
                                        </div>
                                        <div className="cn__footer__top__options__option">
                                            <p className="cn__footer__top__options__option__category">Unique stays</p>
                                            {options4.map((el,i) => <List data={el} key={i} location={location}/>)}
                                        </div>
                                        <div className="cn__footer__top__options__option">
                                            <p className="cn__footer__top__options__option__category">Explore</p>
                                            {options5.map((el,i) => <List data={el} key={i}/>)}
                                        </div>
                                    </div>
                                </SimpleBar>
                                <div className="cn__footer__top__options__option--out">
                                    <p className="cn__footer__top__options__option__category cn__footer__top__options__option__category__milion">Millions of places to stay, one app.</p>
                                    <div className="cn__footer__top__options__option__buttons">
                                        <Link href="https://play.google.com/store/apps/details?id=com.cuddlynest">
                                            <a target="_blank" className="cn__hp__download__info__buttons__android">
                                                <img src="/static/staticpages/home/download/google.svg" alt="Download from Google Store button"/>
                                            </a>
                                        </Link>
                                        <Link href="https://apps.apple.com/app/cuddlynest-hotels-and-homes/id1504837568?uo=2">
                                            <a target="_blank" className="cn__hp__download__info__buttons__apple">
                                                <img src="/static/staticpages/home/download/apple.svg" alt="Download from Apple Store button"/>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </>
                            
                        ):(
                            <>
                                {isDesktop && (
                                    <div className="cn__footer__top__options__option">
                                        <p className="cn__footer__top__options__option__category cn__footer__top__options__option__category__milion">Millions of places to stay, one app.</p>
                                        <div className="cn__footer__top__options__option__buttons">
                                            <Link href="https://play.google.com/store/apps/details?id=com.cuddlynest">
                                                <a target="_blank" className="cn__hp__download__info__buttons__android">
                                                    <img src="/static/staticpages/home/download/googlew.svg" alt="Download from Google Store button"/>
                                                </a>
                                            </Link>
                                            <Link href="https://apps.apple.com/app/cuddlynest-hotels-and-homes/id1504837568?uo=2">
                                                <a target="_blank" className="cn__hp__download__info__buttons__apple">
                                                    <img src="/static/staticpages/home/download/applew.svg" alt="Download from Apple Store button"/>
                                                </a>
                                            </Link>
                                        </div>
                                    </div>
                                )}
                                <div className="cn__footer__top__options__option">
                                    <p className="cn__footer__top__options__option__category">Cuddlynest</p>
                                    {options1.map((el,i) => <List data={el} key={i} handleLogIn={handleLogIn}/>)}
                                </div>
                                <div className="cn__footer__top__options__option">
                                    <p className="cn__footer__top__options__option__category">Unique stays</p>
                                    {options4.map((el,i) => <List data={el} key={i} location={location}/>)}
                                </div>
                                <div className="cn__footer__top__options__option">
                                    <p className="cn__footer__top__options__option__category">Explore</p>
                                    {options5.map((el,i) => <List data={el} key={i}/>)}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {/* {isDesktop && (
                    <div className="cn__footer__bottom__pay">
                        <p className="cn__footer__bottom__pay__title">
                            We accept all major debit and credit cards from customers in every country.
                        </p>
                        <div className="cn__footer__bottom__pay__icons">
                            <div className="cn__footer__bottom__pay__icons__cards">
                                <img src='/static/footer/visa.svg' />
                                <img src='/static/footer/mastercard.svg' />
                                <img src='/static/footer/maestro.svg' />
                                <img src='/static/footer/diners.svg' />
                                <img src='/static/footer/amex.svg' />
                                <img src='/static/footer/jcb.svg' />
                                <img src='/static/footer/union.svg' />
                                <img src='/static/footer/cb.svg' />
                                <img src='/static/footer/discover.svg' />
                            </div>
                            <div className="cn__footer__bottom__pay__icons__divider"/>
                            <div className="cn__footer__bottom__pay__icons__payment">
                                <img src='/static/footer/apple.svg' />
                                <img src='/static/footer/google.svg' />
                                <img src='/static/footer/microsoft.svg' />
                                <img src='/static/footer/ali.svg' />
                                <img src='/static/footer/we.svg' />
                            </div>    
                        </div>
                    </div>
                )} */}
                <div className="cn__footer__bottom">
                    <div className="cn__footer__bottom__sec1">
                        <div className="cn__footer__bottom__sec1__links">
                            <p>
                                <Link href="/terms-and-conditions">
                                    <a
                                        target="_blank"
                                        data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.footer[EAType.CLICK]('T&C') })}
                                    >
                                        Terms & conditions
                                    </a>
                                </Link>
                            </p>
                            <p>
                                <Link href="/privacy-policy">
                                    <a
                                        target="_blank"
                                        data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.footer[EAType.CLICK]('Privacy') })}
                                    >
                                        Privacy policy
                                    </a>
                                </Link>
                            </p>
                        </div>
                    </div>
                    {!isDesktop && (
                        <>
                            <div className="cn__footer__bottom__sec2">
                                <Social/>
                                {isTablet && (
                                    <div className="cn__footer__top__options__option--out">
                                        <p className="cn__footer__top__options__option__category">Millions of places to stay, one app.</p>
                                        <div className="cn__footer__top__options__option__buttons">
                                            <Link href="/#">
                                                <a target="_blank" className="cn__hp__download__info__buttons__android">
                                                    <img src="/static/staticpages/home/download/google.svg" alt="Download from Google Store button"/>
                                                </a>
                                            </Link>
                                            <Link href="/#">
                                                <a target="_blank" className="cn__hp__download__info__buttons__apple">
                                                    <img src="/static/staticpages/home/download/apple.svg" alt="Download from Apple Store button"/>
                                                </a>
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* <div className="cn__footer__bottom__pay">
                                <p className="cn__footer__bottom__pay__title">
                                    We accept all major debit and credit cards from customers in every country.
                                </p>
                                <div className="cn__footer__bottom__pay__icons">
                                    <div className="cn__footer__bottom__pay__icons__cards">
                                        <img src='/static/footer/visa.svg' />
                                        <img src='/static/footer/mastercard.svg' />
                                        <img src='/static/footer/maestro.svg' />
                                        <img src='/static/footer/diners.svg' />
                                        <img src='/static/footer/amex.svg' />
                                        <img src='/static/footer/jcb.svg' />
                                        <img src='/static/footer/union.svg' />
                                        <img src='/static/footer/cb.svg' />
                                        <img src='/static/footer/discover.svg' />
                                    </div>
                                    <div className="cn__footer__bottom__pay__icons__divider"/>
                                    <div className="cn__footer__bottom__pay__icons__payment">
                                        <img src='/static/footer/apple.svg' />
                                        <img src='/static/footer/google.svg' />
                                        <img src='/static/footer/microsoft.svg' />
                                        <img src='/static/footer/ali.svg' />
                                        <img src='/static/footer/we.svg' />
                                    </div>    
                                </div>
                            </div> */}
                        </>
                    )}
                </div>
            </div>
        </footer>
    )
}

export default memo(Footer);