import { memo } from "react";
import DiscountTooltip from "./DiscountTooltip";

const Discount = ({
    isActive,
    priceDrop,
    oldPrice,
    parent
}) => {
    if (!isActive) {
        return null;
    }

    return (
        // <DiscountTooltip
        //     parent={parent}
        //     content={`This price just dropped ${priceDrop} lower than usual!`}
        // >
            <div className='strike-through-price'>
                <span className='strike-through-price__number'>{oldPrice}</span>
            </div>
        // </DiscountTooltip>
    );      
};

export default memo(Discount);
