import useCurrencyConverter from "@/src/utils/customHooks/useCurrencyConverter";
import { useAmenityIcons, useRemoveDuplicatedIcons } from "../hooks";
import { normalizeUrl } from '@/src/utils/globalFunc';
import useDateFormatter from '@/containers/homePage/Omnisearch/useDateFormatter';
import { getWeekend } from '@/src/utils/globalFunc';
import { config } from '@/src/utils/config';
import { DestinationModel } from "@/containers/homePage/Omnisearch/Destination";
import { normalizeLocation } from "@/src/utils/locationNormalizers";
import { DateRangeModel } from "@/containers/homePage/Omnisearch/DateRange";
import { useSelector } from "react-redux";

export const getPlace = (destination) => {

    const {city,state,country} = destination;

    const getGeolocationType = ({ city, state }) => {
        if (city) {
          return 'City';
        }
        if (state) {
          return 'State';
        }
        return 'Country'
    };

    const place = getGeolocationType({city,state}) === 'City' ? city : getGeolocationType({city,state}) === 'State' ? state : country;

    return place
}

export const setElementMinHeight = ({elementsArray, parent, variable}) => {
  const elementsHeights = elementsArray.map(element => element.offsetHeight);
  const maxHeight = Math.max.apply(this, elementsHeights);
  const minHeight = Math.min.apply(this, elementsHeights);
  const setHeight = minHeight !== maxHeight && maxHeight;
  if(setHeight){
      parent.style.setProperty(variable, `${setHeight}px`);
  }
};

export const setPlacePin = (info,destination) => {
  let location = {
    loc1:'',
    loc2:''
  };
  if(destination?.locationType === 'Country'){
    location = {loc1:info.state, loc2:info.country}
  }else if (destination?.locationType === 'City'){
    location = {loc1:info.neighborhood_name, loc2:info.city}
  }else{
    location = {loc1:info.city, loc2:info.state}
  }
  return location;
}

export const setSectionData = (info,destination) => {
  const { currency } = useSelector(state => state.currencies);

  const convertTo = useCurrencyConverter();
  const propertiesWithSvgs = useAmenityIcons(info);
  const getUniqueIcons = useRemoveDuplicatedIcons();

  let data = [];
  propertiesWithSvgs.map(el => {

      const price = typeof window === 'undefined' ? el.price : convertTo({
          amount: el.price.amount,
      });

      const location = setPlacePin(el,destination);

      let obj = {
          title: el.name,
          img: el.img,
          rating: parseInt(el.rating),
          amenities: getUniqueIcons(el).slice(0,3),
          facilities: el.things_to_do_updated.slice(0,5),
          url: normalizeUrl(el.url),
          price: `${price.currency_symbol} ${price.amount}`,
          priceFull: {
            ...el.price,
            amount: convertTo({
              amount: el.price.amount,
            })?.amountNum,
            rate: currency.rate,
            currency: currency.short,
            currency_symbol: currency.symbol,
            tax_fee_label: 'taxes and fees',
          },
          property: el.property_type,
          tags: {
            breakfast: el.breakfast_included,
            cancellation: el.free_cancellation_policy
          },
          urating: {
              rate: el.grs_new || 0,
              status: el.grs_adjective_new,
              amount: el.grs_total_reviews ? parseInt(el.grs_total_reviews) : 0
          },
          location: location
      }
      data.push(obj);

  });

  return data;

}

export const setSliderData = (data) => {
  return {
    data,
    count: 3,
    card: 'GP',
    cardMargin: 52,
    showBar: false,
  };
}


export const updateURLParam = (url) => {

  //Checkin/Checkout
  const dateFormater = useDateFormatter();
  const [checkin, checkout] = getWeekend(1).map(date => dateFormater(date));

  let urlValue = normalizeUrl(url);
  if (url.startsWith('http')) {
      urlValue = url
  }else if (url.startsWith('/')) {
      urlValue = `${config.current_url}${url}`;
  }else {
      return url;
  }

  const ldpUrl = new URL(urlValue);
  const { searchParams } = ldpUrl;
  

  searchParams.set('checkin', checkin);
  searchParams.set('checkout', checkout);

  return ldpUrl.href;
};


export const getOmniInitializer = bootstrapData => ({
  setDestinationState,
  setNextStepControlState,
  setDatesState,
  fetchAtractionNearby
}) => {
  const { type, city, state, country, lat, lon, zoom_in, mob_zoom_in, viewport_bounds: viewportBounds} = bootstrapData.meta_data ?? {};
  const formmatedObj = viewportBounds && JSON.parse(viewportBounds.replace(/'/g, '"'));

  const name = city || state || country;
  const [startDate, endDate] = getWeekend(1);

  setDestinationState(new DestinationModel({
      address: normalizeLocation([city, state, country].filter(Boolean).join(', ')),
      name,
      slug: name.toLowerCase(),
      type,
      inputValue: name,
      location:{
          lat: lat,
          lon: lon
      },
      viewportBounds: formmatedObj,
      zoomLevel: zoom_in,
      mobileZoomLevel: mob_zoom_in
  }));

  setDatesState(new DateRangeModel({
      startDate,
      endDate
  }));

  setNextStepControlState?.(true);

  fetchAtractionNearby({
      city,
      state,
      country,
      latitude: lat || 1,
      longitude: lon || 1
  });
};
