import React, { memo } from 'react';
import { recommendedWiredKeys } from '@/containers/searchResults/DropDownCollection/DropDownCmps/Recommended/useRecommended';
import PayLetterStickers from '../icons/PayLater';

import clsx from 'clsx';
import withCancellationPolicies from './hocs/withCancellationPolicies';

const PayLetterSticker = ({ className, cancellationPolicies }) =>
    [cancellationPolicies]
        .flat()
        .filter(Boolean)
        .some(policy => Object.is(
            policy?.toLocaleLowerCase(),
            recommendedWiredKeys.FREE_CANCELLATION.toLocaleLowerCase()
        )) && (
        <div className={clsx('property-card_pay-letter-sticker', className)}>
            <PayLetterStickers className='property-card_free-cancellation-sticker_icon' />
            <p>Pay Later</p>
        </div>
    );

PayLetterSticker.defaultProps = {
    cancellationPolicies: []
};

export default memo(
    withCancellationPolicies(PayLetterSticker)
);
