const withCancellationPolicies = WrappedComponent => ({ cancellationPolicies = [], ...props }) => (
    <WrappedComponent
        {...props}
        cancellationPolicies={(
            Array.isArray(cancellationPolicies)
                ? cancellationPolicies
                : []
        )}
    />
);

export default withCancellationPolicies;
