import React, { memo } from 'react';
import clsx from 'clsx';

//Import component
import Image from '@/containers/newHomePage/components/Image';
import { handleJSClick } from '@/components/Slider/utils';
import useCurrencyConverter from '@/utils/customHooks/useCurrencyConverter';
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';

const Card7 = ({ data, linkType }) => {
    const renderComponent = getLinkStrategies(linkType.type === 'a');
    const convertTo = useCurrencyConverter();
    const deal = convertTo({ amount: data.deal });

    return (
        <div className='cn__card7__wrapper'>
            {renderComponent({ data, target: linkType.target })}
            <div className="cn__card7__info">
                <p className="cn__card7__info__title">{data.title}</p>
                <p className="cn__card7__info__subtitle">
                    Deals starting at&nbsp;
                    <span className="cn__card7__info__subtitle__bold">{`${deal.currency_symbol}${deal.amount} ${data.post_text}`}</span>
                </p>
            </div>
        </div>
    );
}

const LinkWrapper = ({ className, children, dataCnGaClick, onClick }) => (
    <div
        className={clsx(['cn__card', 'cn__card7'], className)}
        data-cn-ga-click={dataCnGaClick}
        onClick={onClick}
    >
        {children}
    </div>
);

function getLinkStrategies(strategy) {
    const { isDesktop, isTablet } = useDeviceDetect()
    return new Map()
        .set(true, ({ data, target }) => (
            <LinkWrapper dataCnGaClick={data.dataCnGaClick}>
                <a href={data.url} target={target}>
                    <Image src={data.src} alt={data.title} sources={data.sources} />
                </a>
            </LinkWrapper>
        ))
        .set(false, ({ data, target }) => {
            const onClickHandler = () => {
                handleJSClick(data.url, target);
            };

            return (
                <LinkWrapper
                    dataCnGaClick={data.dataCnGaClick}
                    onClick={onClickHandler}
                >
                    <Image src={isDesktop ? data.desktopImage : isTablet ? data.tabletImage : data.mobileImage} alt={data.title} />
                </LinkWrapper>
            );
        })
        .get(strategy);
}

export default memo(Card7);
