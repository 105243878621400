import { useCallback } from "react";
import { useSelector } from "react-redux";

export default function useRecentSearch() {
    const searchTerm = useSelector(state => state.searchResults.searchTerm);
    const getRecentSearch = useCallback(() => {
        const recentSearch = localStorage.getItem('recentSearch');
        try {
            const recentSearchParsed = JSON.parse(recentSearch);
            return Array.isArray(recentSearchParsed) ? recentSearchParsed : [];
        } catch { }
        return [];
    }, []);

    const updateRecentSearch = useCallback((destinationModel, datesModel, guestsModel, selectedInterests = null) => {
        const filteredState = getRecentSearch().filter(it => {
            if (destinationModel.id && (it.addrData.formattedAddress === destinationModel.inputValue)) {
                return false;
            }
            return it.addrData.formattedAddress !== destinationModel.searchTerm;
        });
        const updatedState = [
            {
                activeTab: 'home',
                addrData: {
                    address: destinationModel.address,
                    formattedAddress: destinationModel.searchTerm,
                    id: destinationModel.id,
                    name: destinationModel.name,
                    slug: destinationModel.slug,
                    type: destinationModel.type,
                    location: destinationModel.location,
                    viewportBounds: destinationModel.viewportBounds,
                    zoomLevel: destinationModel.zoomLevel,
                    mobileZoomLevel: destinationModel.mobileZoomLevel,
                    propertyCount: destinationModel.propertyCount
                },
                guests: {
                    adults: guestsModel.adultCount,
                    children: guestsModel.children,
                    childrenVr: guestsModel.childCount,
                    infants: guestsModel.infantCount,
                    rooms: guestsModel.roomCount
                },
                startDate: datesModel.startDate,
                endDate: datesModel.endDate,
                interests: selectedInterests
            },
            ...filteredState.slice(0, 4)
        ];
        localStorage.setItem('recentSearch', JSON.stringify(updatedState));
    }, [getRecentSearch]);
    const updateRecentSearchDatesAndGuest = useCallback((source, data) => {
        let parsedRecentSearch = getRecentSearch();
        if (Array.isArray(parsedRecentSearch) && parsedRecentSearch.length > 0 && parsedRecentSearch[0].addrData.formattedAddress === searchTerm) {
            const modifiedRecentSearch = [...parsedRecentSearch];
            if(source === 'date'){
                modifiedRecentSearch[0].startDate = data.startDate;
                modifiedRecentSearch[0].endDate = data.endDate;
            } else {
                modifiedRecentSearch[0].guests = data;
            }
        const updatedRecentSearch = JSON.stringify(modifiedRecentSearch);
        localStorage.setItem('recentSearch', updatedRecentSearch);
        }
    }, [getRecentSearch])
    return {
        getRecentSearch,
        updateRecentSearch,
        updateRecentSearchDatesAndGuest
    };
}